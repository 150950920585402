import React, { FC } from "react"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import { formatDateToYearAndMonth, generateContentObject } from "utils"
import { ISearchIndexProps } from "types"
import Card from "components/Shared/Card"
import styled from "styled-components"
import { spacing } from "styles/spacing"
import { breakpoint } from "styles/breakpoints"

interface ISearchResultsProps {
  results: ISearchIndexProps[]
  filter: {
    type: string
    blog?: string
  }
}

const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  & > :not(:last-child) {
    margin-bottom: ${spacing.lg}px;
  }
  @media ${breakpoint.lg} {
    flex-direction: row;
    margin: 0 -${spacing.lg / 2}px;
  }
`

const ListItem = styled.li`
  height: 380px;
  width: 100%;
  @media ${breakpoint.lg} {
    height: 450px;
    width: calc(50% - ${spacing.lg}px);
    margin: 0 ${spacing.lg / 2}px;
  }
`

const SearchResults: FC<ISearchResultsProps> = ({ results, filter }) => {
  if (filter.type === "Film") filter.type = "Film"
  if (filter.type === "Events") filter.type = "Event"
  if (filter.type === "Foto") filter.type = "Photo"
  if (filter.type === "Category") filter.type = "Post"

  results = results.filter(result => {
    if (filter.type === "404") return result
    return result.type == filter.type
  })
  if (filter.blog) {
    results = results.filter(result => {
      return result["categories"].nodes[0].id === filter.blog
    })
  }
  if (!Boolean(results.length)) return null

  const formatMonth = (month: string) => {
    return format(new Date(month), "MMMM", { locale: sv }).toUpperCase()
  }

  const formatTypes = type => {
    if (type === "Photo") return "Foto"
    if (type === "Post") return "Inlägg"
    if (type === "Film") return "Film"
    if (type === "Event") return "Events"
    if (type === "Page") return "Sidor"
  }

  const organizeResults = filter => {
    return results.reduce((acc, value) => {
      let key = ""
      key = formatDateToYearAndMonth(value.date)
      if (filter === "404") {
        key = value.type
        key = formatTypes(key)
      }
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(value)

      acc[key].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      )

      return acc
    }, {})
  }

  return (
    <>
      {Object.entries(organizeResults(filter.type))
        .sort()
        .map((type: [string, ISearchIndexProps[]]) => {
          return (
            <section key={type[0]}>
              <h5>{filter.type === "404" ? type[0] : formatMonth(type[0])}</h5>
              <List>
                {type[1].map(item => {
                  const content = generateContentObject(item)
                  return (
                    <ListItem key={item.id}>
                      <Card content={content} />
                    </ListItem>
                  )
                })}
              </List>
            </section>
          )
        })}
    </>
  )
}

export default SearchResults
