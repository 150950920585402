import React, { FC, useState, useEffect } from "react"
import { Index } from "elasticlunr"
import DatePicker from "./DatePicker"
import SearchInput from "./Search"
import styled from "styled-components"
import { spacing } from "styles/spacing"
import SearchResults from "./SearchResults"
import { breakpoint } from "styles/breakpoints"
import { formatDateToYearAndMonth } from "utils"
import { IIndexSearchProps } from "hooks/searchIndex"

interface ISearchProps {
  filter?: {
    type: string
    blog?: string
  }
  searchIndex: IIndexSearchProps
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin-bottom: ${spacing.sm}px;
  }
  @media ${breakpoint.lg} {
    flex-direction: row;
    & > :first-child {
      margin-right: ${spacing.lg}px;
      margin-bottom: 0;
    }
  }
  align-items: center;
  justify-content: center;
`

const Search: FC<ISearchProps> = ({ searchIndex, filter }) => {
  const [state, setState] = useState({
    query: ``,
    results: [],
    startDate: new Date(),
  })
  const url = typeof window !== "undefined" ? window.location.href : ""

  let index

  const getOrCreateIndex = () =>
    index
      ? index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(searchIndex)

  const search = e => {
    const query = e.target.value
    index = getOrCreateIndex()
    setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => {
          return index.documentStore.getDoc(ref)
        }),
      startDate: state.startDate,
    })
  }

  const addQueryToResults = (date: Date) => {
    if (!date) {
      date = new Date()
    }
    for (const [key, value] of Object.entries(searchIndex.documentStore.docs)) {
      if (value.date && value.date.includes(formatDateToYearAndMonth(date))) {
        let query = {
          title: value.title,
          link: value.link,
          id: value.id,
          date: value.date,
          type: value.type,
          iframe: value.iframe,
          excerpt: value.excerpt,
          featuredImage: value.featuredImage,
          categories: value.categories,
        }
        setState(prevState => ({
          ...prevState,
          results: [...prevState.results, query],
          startDate: date,
        }))
      }
    }
  }

  const dateSearch = (date: Date) => {
    setState({
      query: ``,
      results: [],
      startDate: date,
    })

    addQueryToResults(date)
  }

  useEffect(() => {
    dateSearch(new Date())
  }, [])

  return (
    <div style={{ minHeight: 300 }}>
      <Wrapper>
        <SearchInput value={state.query} search={search} />
        {filter.type === "404" ? null : (
          <DatePicker startDate={state.startDate} onSelectDate={dateSearch} />
        )}
      </Wrapper>
      <SearchResults results={state.results} filter={filter} />
    </div>
  )
}

export default Search
