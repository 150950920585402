import React, { FC } from "react"
import { SearchIcon } from "components/Icons"
import styled from "styled-components"
import { colors } from "styles/colors"

interface ISearchProps {
  search: any
  value: string
}

const Wrapper = styled.label`
  border-bottom: 1px solid ${colors.white};
  & input::placeholder {
    color: #fff;
  }
`

const Search: FC<ISearchProps> = ({ search, value }) => {
  return (
    <Wrapper>
      <input type="text" placeholder="Sök" value={value} onChange={search} />
      <SearchIcon />
    </Wrapper>
  )
}

export default Search
