import { useStaticQuery, graphql } from "gatsby"

export interface IIndexSearchProps {
  documentStore: {
    docs: {
      id: {
        date: String
        title: String
        link: String
        id: String
        excerpt?: string
        featuredImage?: string
        type: String
        iframe?: string
        categories?: { nodes: [{ id: string }] }
      }
    }
  }
}

export interface ISearchIndexProps {
  siteSearchIndex: {
    index: IIndexSearchProps
  }
}
export const useSearchIndex = () => {
  const { siteSearchIndex }: ISearchIndexProps = useStaticQuery(graphql`
    query SearchIndex {
      siteSearchIndex {
        index
      }
    }
  `)
  return siteSearchIndex.index
}
