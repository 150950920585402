import React, { FC } from "react"
import DatePickerInput, { registerLocale } from "react-datepicker"
import { sv } from "date-fns/locale"
import "react-datepicker/dist/react-datepicker.css"
import "./styles.scss"
import { CalenderIcon } from "components/Icons"
import styled from "styled-components"
import { colors } from "styles/colors"
registerLocale("sv", sv)

interface IDatePickerProps {
  startDate: Date
  onSelectDate: (date: any) => void
}

const Wrapper = styled.label`
  display: flex;
  padding-bottom: 1px;
  border-bottom: 1px solid ${colors.white};
`

const DatePicker: FC<IDatePickerProps> = ({ startDate, onSelectDate }) => {
  return (
    <Wrapper>
      <DatePickerInput
        selected={startDate}
        locale="sv"
        onChange={date => onSelectDate(date)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        showTwoColumnMonthYearPicker
      />
      <CalenderIcon />
    </Wrapper>
  )
}

export default DatePicker
